
import React, { Component } from "react";
import Select, { components } from 'react-select';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { fetchApplicationList, setApplicationId, setQuestion, selectChatId } from "../../../actions/index";
import * as routes from "../../../constants/routes";
import ChatHistory from './ChatHistory'
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicatonList: {},
      sampleQuestions: [],
      sampleApplications: [],
      hubSpotapplicatons: [],
    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      window.location.href = '/';
    }
  }

  componentDidMount() {
    let currentUser = this.props.currentUser
    if (currentUser === '' || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
      //return false;
    }

    this.props.selectChatId('0');
    this.props.fetchApplicationList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.applicatonList && prevProps.applicatonList !== this.props.applicatonList) {
      let applications = [];
      let sampleQuestions = [];
      let sampleApplications = [];

      this.props.applicatonList.forEach((ele) => {
        let application = {
          value: ele.id,
          label: ele.name,
          token: ele.token,
        };
        applications.push(application);

        if (sampleApplications.length < 6) {
          sampleApplications.push(application);
        }

        // if (ele.settings && ele.settings.shots && Object.keys(ele.settings.shots).length > 0 && sampleQuestions.length < 4) {
        //   let sampleQue = {
        //     "question": Object.keys(ele.settings.shots)[0],
        //     "applicationName": ele.name,
        //     "applicationId": ele.id,
        //     "token": ele.token
        //   };

        //   sampleQuestions.push(sampleQue);
        // }
      });
      let applicatonList = this.props.applicatonList;

      //let hubSpotapplicatons = applicatonList.filter((val) => { return val.name.includes('HUBSPOT') });
      let hubSpotapplicatons = applicatonList.filter((val) => { return val.id === 'c98fd773-f374-41fc-bb5c-9779b7fced8a'});

      //applicatonList = applicatonList.filter((val) => { return val.name === 'HUBSPOT DEMO' });
      applicatonList = applicatonList.filter((val) => { return val.id === 'c98fd773-f374-41fc-bb5c-9779b7fced8a' });

      applicatonList = applicatonList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      applicatonList = applicatonList[0];


      if (applicatonList.settings && applicatonList.settings.shots && Object.keys(applicatonList.settings.shots).length > 0) {
        Object.keys(applicatonList.settings.shots).forEach((shot) => {
          if (sampleQuestions.length < 4) {
            let sampleQue = {
              "question": shot,
              "applicationName": applicatonList.name,
              "applicationId": applicatonList.id,
              "token": applicatonList.token
            };

            sampleQuestions.push(sampleQue);
          }
        })
      }

      this.setState({
        applicatonList: applications,
        sampleQuestions: sampleQuestions,
        sampleApplications: sampleApplications,
        hubSpotapplicatons: hubSpotapplicatons,
      });
    }

  }
  appointmentClick(applicationId, token) {

    this.props.setQuestion(applicationId, '', token);
    this.props.history.push(routes.DASHBOARDRESULT);
    // dispatch({
    //   type: SET_APPLICATIONID,
    //   payload: applicationId,
    // });
  }

  selectQuestion(application_id, question, token) {
    this.props.setQuestion(application_id, question, token);
    this.props.history.push(routes.DASHBOARDRESULT);
  }
  selectApplication(e) {
    let applicationId = e.value;
    let applicatonList = this.props.applicatonList;
    applicatonList = applicatonList.filter((val) => { return val.id === applicationId });
    let token = applicatonList[0].token;
    //this.props.setApplicationId(applicationId);
    this.props.setQuestion(applicationId, '', token);
    this.props.history.push(routes.DASHBOARDRESULT);
  }
  handlerChatListClick(id) {
    //this.props.selectChatId(id);
    this.props.history.push(routes.DASHBOARDRESULT);
  }
  handlerNewChat() {
    this.props.setQuestion('', '', '');
    this.props.history.push(routes.DASHBOARDRESULT);
  }
  render() {
    const DropdownIndicator = (
      props
    ) => {
      return (
        <components.DropdownIndicator {...props}>
          <img src="/images/search.png" className="" alt="send" />
        </components.DropdownIndicator>
      );
    };
    return (
      <div className="grow-1 flex flex-row">
        {/* <ChatHistory handlerChatListClick={(id) => this.handlerChatListClick(id)} handlerNewChat={() => this.handlerNewChat()} selected_chat_id=""></ChatHistory> */}
        <div className="flex flex-col w-100 middle-section-scroll position-relative welcome-dashboard">
          <div className="max-w-768 mx-auto w-full my-3 px-2">
            <div className="welcome welcome-text mx-auto">
              Welcome to Ask My Data for HubSpot: Blattner Technologies
            </div>
            <div className="text-center mx-auto mt-2">
              A platform designed for Blattner Technologies staff to ask questions about company contacts, deals, and support tickets across multiple HubSpot pipelines
            </div>
            <div className="my-3">
              <Select
                className="rounded-8 search-application"
                onChange={(e) => this.selectApplication(e)}
                options={this.state.applicatonList}
                placeholder={"Search Application"}
                isSearchable={true}
                components={{ DropdownIndicator }}
              />
            </div>

          </div>
          {/* <ButtonGroup className="d-flex flex-wrap gap-12 mx-auto mb-auto px-2">
            {this.state.sampleApplications.length > 0 && this.state.sampleApplications.map((application, index) => {
              return <Button className={"btn btn-theme rounded-8"} onClick={(value, token) => this.appointmentClick(application.value, application.token)}>{application.label}</Button>
            })
            }

          </ButtonGroup> */}
          <div className="max-w-768 mx-auto w-full px-2 flex flex-wrap gap-12 mb-3 mt-auto">
            {this.state.hubSpotapplicatons.length > 0 && this.state.hubSpotapplicatons.map((application, index) => {
              return <div className={`${this.state.hubSpotapplicatons.length % 2 != 0 && index == 0 ? 'w-100' : ''} rounded-8 bg-light-gray p-2 border-gray question-suggestion text-left d-flex align-items-center`}>
                <div className="mr-auto">
                  <p className="mb-0">{application.name}</p>
                  <div className="mb-0 small"><strong>Description: </strong>{application.description}</div>
                  <small className="mb-0"><strong>Model Type: </strong>{application.settings.model.type}</small>
                  <small className="mb-0"><strong>Model Name: </strong>{application.settings.model.details.name}</small>
                  <small className="mb-0"><strong>Is Monitored: </strong>{application.is_monitored}</small>
                  <small className="mb-0"><strong>Has Guardrails: </strong>{application.has_guardrail}</small>
                  <small className="mb-0"><strong>Created At: </strong>{moment(application.created_at).format('MM/DD/YYYY HH:mm')}</small>
                </div>
                <button type="button" onClick={(value, token) => this.appointmentClick(application.id, application.token)}>
                  <img src="images/send_light.png" className="" alt="" height="" width="" />
                </button>
              </div>
            })}


            {this.state.sampleQuestions.length > 0 && this.state.sampleQuestions.map((sampleQuestion, val) => {
              return <button type="button" className="rounded-8 bg-light-gray p-2 border-gray question-suggestion text-left d-flex align-items-center" onClick={(e) => this.selectQuestion(sampleQuestion.applicationId, sampleQuestion.question, sampleQuestion.token)}>

                <div className="mr-auto">
                  <p className="mb-0">{sampleQuestion.applicationName}</p>
                  <small className="mb-0">{sampleQuestion.question}</small>
                </div>
                <img src="images/send_light.png" className="" alt="" height="" width="" />
              </button>
            })
            }


          </div>
          {/* <ApplicationModal /> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ptpuser: state.security.ptpuser,
    applicatonList: state.dashboard.applicatonList,
    currentUser: state.security.user,
    selectedChatId: state.dashboard.selectedChatId,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      fetchApplicationList,
      setApplicationId,
      setQuestion,
      selectChatId,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);