import React, { Component } from "react";
import { Dropdown } from 'react-bootstrap'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from 'react-bootstrap/Button';
import { fetchUserList, disableUser, enableUser, deleteUser, sendResetPassword } from "../../../actions/index";
import DataTable from '../../GlobalComponent/Datatable';
import Pagination from '../../GlobalComponent/Pagination';
import { alertService } from "./../../GlobalComponent/alertService";
import * as routes from "../../../constants/routes";
import AddEditUser from "./AddEditUser";
import ConfirmationDeleteModal from '../../GlobalComponent/ConfirmationDeleteModal';
class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            perPage: 10,
            totalItems: 0,
            sortColumn: "",
            sortOrder: "",
            totalPages: 0,
            AddEditUserModal: false,
            EditElement: null,
            mode: "Add",
            userId: "",
            users: {},
            sortInfo: {
                sortBy: 'name',
                sortOrder: 'asc'
            },
            pageInfo: {
                "page": 1,
                "perPage": 100,
                "total": 100,
                "totalPages": '2'
            },
            currentUser: "",
            modalType: '',
            selected_user_id: '',
        };
    }
    componentWillMount() {
        const tableColumns = [

            {
                name: 'First Name',
                selector: 'firstName',
                sortable: false
            },
            {
                name: 'Last Name',
                selector: 'lastName',
                sortable: false
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: false
            },
            {
                name: 'Phone',
                selector: 'phoneNumber',
                sortable: false,
                columnClassName: "w-200px",
            },
            {
                name: 'Action',
                selector: 'action',
                headerClassName: 'text-right',
                columnClassName: 'text-right w-400',
                cell: row => (
                    <>
                        {row.uid !== this.state.currentUser.user_id &&
                            <><Button variant="link" onClick={() => this.changeStatus(row)}>{row.disabled ? 'Re-Enable User' : 'Disable User'}</Button> |
                            </>
                        }
                        <Button variant="link" onClick={() => this.sendResetPassword(row)}> Reset Password</Button> |
                        <Button variant="link" onClick={() => this.editUser(row)}>
                            <img src="/images/edit_black.png" className="" alt="send" width={20} />
                        </Button>
                        <div className="d-inline-block" style={{ minWidth: "47px" }}>
                            {row.uid !== this.state.currentUser.user_id &&
                                <Button variant="link" onClick={() => this.deleteUser(row.uid)}>
                                    <img src="/images/delete_red.svg" className="" alt="send" width={20} />
                                </Button>
                            }
                        </div>
                    </>
                )
            }
        ];
        this.setState({
            columns: tableColumns
        });
    }
    componentDidMount() {
        let currentUser = this.props.currentUser;
        if (currentUser === '' || Object.keys(currentUser).length === 0) {
            window.location.href = '/';
            return false;
        }
        else {
            this.setState({ currentUser: currentUser });
        }
        if (!currentUser.admin) {
            this.props.history.push(routes.DASHBOARD);
        }
        //console.log("dataProvider:::",dataProvider.getList());
        //console.log("authProvider:::",authProvider);
        this.props.fetchUserList();
    }
    componentDidUpdate(prevProps) {
        if (this.props.user && prevProps.user !== this.props.user) {
            this.setState({ users: this.props.user });
        }
        if (this.props.userStatus && prevProps.userStatus !== this.props.userStatus) {
            alertService.success(this.props.userStatus.message, { autoClose: true });
            this.props.fetchUserList();
        }
        if (this.props.userDelete && this.props.userDelete !== prevProps.userDelete) {
            alertService.success(this.props.userDelete.message, { autoClose: true });
            this.setState({ modalType: false });
            this.props.fetchUserList();
        }
        if (this.props.userResetPassword && this.props.userResetPassword !== prevProps.userResetPassword) {
            alertService.success("Reset Password link sent successfully on " + this.props.userResetPassword, { autoClose: true });
            this.setState({ modalType: false });
            this.props.fetchUserList();
        }

    }
    changeStatus(user) {
        if (user.disabled) {
            this.props.enableUser(user);
        } else {
            this.props.disableUser(user);
        }

    }
    editUser(user) {
        this.setState({
            EditElement: user,
            mode: "Edit",
            AddEditUserModal: true
        });
        //this.handleOpen();
    }
    sendResetPassword(user) {
        this.props.sendResetPassword(user.email);
    }
    deleteUser(id, type = "DELETE") {
        this.setState({
            modalType: type,
            selected_user_id: id
        })
    }
    handleOpen = () => this.setState({ AddEditUserModal: true, mode: "Add" });
    handleClose = () =>
        this.setState({ AddEditUserModal: false });
    onChangePage(pageOfItems) {
        let page_info = this.state.pageInfo;
        page_info.page = pageOfItems.currentPage;
        page_info.perPage = pageOfItems.pageSize;
        this.setState({ pageInfo: page_info });
        //this.fetchRecords();
    }

    onChangePerPage(pageInfo) {
        let page_info = this.state.pageInfo;
        page_info = pageInfo;
        //console.log(page_info);

        this.setState({ pageInfo: page_info });
        //this.fetchRecords();
    }
    closeModal() {
        this.setState({
            modalType: '',
        });
    }
    deleteRecord(id) {
        this.props.deleteUser(id);
    }
    render() {
        const css = `
        .usertable {
            max-width:100%;
        }
        .menu-hide-logo-position{
            left: 10px;
    width: calc(100% - 10px);
    transition: all 0.3s ease;
        }
    `
        return (
            <>
               
                <style>{css}</style>
                <div className="grow-1 flex flex-col">
                    <div className="d-flex flex-wrap align-items-center m-3">
                        <h2 className="">Users</h2>
                        <button className="ml-auto bg-primary-theme pointer py-2 px-4 border-0 rounded-14 text-white font-figtree font-700 font-18" variant="contained" onClick={() => this.handleOpen()}>Invite User</button>
                    </div>
                    {this.state.AddEditUserModal &&
                        <AddEditUser
                            mode={this.state.mode}
                            EditElement={this.state.EditElement}
                            handleClose={this.handleClose}
                            AddEditUserModal={this.state.AddEditUserModal}
                            page={this.state.page}
                            perPage={this.state.perPage}
                            sortColumn={this.state.sortColumn}
                            sortOrder={this.state.sortOrder}
                            userId={this.state.userId}
                        />
                    }
                    <div className="middle-section-scroll position-relative px-3">
                        {this.state.users !== '' &&
                            <DataTable
                                title="Users"
                                columns={this.state.columns}
                                data={this.state.users}
                                sortInfo={this.state.sortInfo}
                                colSpan='10'
                                pageInfo={this.state.pageInfo}
                            />
                        }
                        <Pagination items={this.state.users} pageInfo={this.state.pageInfo} onChangePage={this.onChangePage.bind(this)} onChangePerPage={this.onChangePerPage.bind(this)}></Pagination>
                    </div>
                </div>
                {this.state.modalType == 'DELETE' ?
                    <ConfirmationDeleteModal confirmeDelete={(id) => this.deleteRecord(id)} closePopup={this.closeModal.bind(this)} showDeletePopup={(this.state.modalType == 'DELETE') ? true : false} recordId={this.state.selected_user_id}></ConfirmationDeleteModal> : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        //provider: state.provider.providerList,
        currentUser: state.security.user,
        user: state.user.userList,
        userStatus: state.user.userStatus,
        userDelete: state.user.userDelete,
        userResetPassword: state.user.userResetPassword,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchUserList,
            disableUser,
            enableUser,
            deleteUser,
            sendResetPassword,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
