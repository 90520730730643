
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
class ResultThread extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastQuestion: "",
      result: "",
    };
  }

  componentDidMount() {
    let currentUser = this.props.currentUser
    if (currentUser == '' || Object.keys(currentUser).length == 0) {
      window.location.href = '/';
      return false;
    }
    this.setState({
      result: this.props.result,
      lastQuestion: this.props.lastQuestion
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.result && prevProps.result != this.props.result) {
      this.setState({
        result: this.props.result,
        lastQuestion: this.props.lastQuestion
      });
    }
  }
  render() {

    return (
      <div>
        {Object.keys(this.state.result).length > 0 &&
          <>
            <div className="bg-light-1 py-3 px-1">
              <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
                <img src="/images/profile.png" alt="profile" width={32} />
                <div>
                  {this.state.lastQuestion}
                </div>
              </div>
            </div>
            <div className="pt-3 pb-1 px-1">
              <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
                <img src="/images/logo-icon.png" alt="ptp" width={32} />
                
                  <div class="table-responsive">
                  {this.state.result.type === 'table' &&
                    <table class="table table-striped table-hover border mb-0">
                      <thead class="table-primary text-light">
                        <tr>
                          {this.state.result.data.columns.map((col, i) => {
                            return <th scope="col">{col}</th>
                          })}

                        </tr>
                      </thead>
                      <tbody>
                        {this.state.result.data.records.map((val, i) => {
                          return <tr>
                            {val.map((cell, i) => {
                              return <td scope="row">{cell}</td>
                            })}
                          </tr>
                        })}
                      </tbody>
                    </table>
                  }
                  {this.state.result.type === 'text' &&
                    <div>
                      {this.state.result.data}
                    </div>
                  }
                
              </div>
              <div className="d-flex gap-12 like-icon">
                {/* <a href="javascript:void(0)" className="like-dislike"><img src="images/thumb_down_black.png" alt="ptp" /></a>
                  <a href="javascript:void(0)" className="like-dislike"><img src="images/thumb_up_black.png" alt="ptp" /></a> */}
              </div>
            </div>
          </div>
      </>
        }

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ResultThread);