import axios from "axios";
import setJWTToken from "../securityUtils/setJWTToken";
import jwt_decode from "jwt-decode";
import { localhost, GET_ERRORS, NODEURL, PTP_AUTHURL, FETCH_CONFIG, handleError } from "../actions/index";
import CryptoJS from "crypto-js";
//for authorization
export const EMAIL_CHANGED = "email_changed";
export const PASSWORD_CHANGED = "password_changed";
export const LOGIN_USER_FAIL = "login_user_fail";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_PTP_CURRENT_USER = "SET_PTP_CURRENT_USER";


export const setCurrentUser = (token) => async (dispatch) => {
  try {
    let decoded = jwt_decode(token);
    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
    });
  }

  catch (err) {
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};
export const ptpAuthorization = (payload, url) => async (dispatch) => {
  try {
    // post => Login Request

    const res = await axios.post(
      url,
      payload,
    );
    // extract token from res.data
    let { accessToken } = res.data;
    //let decoded = '';
    if (accessToken) {
      setJWTToken(accessToken);
      sessionStorage.setItem("jwtTokenPTP", "Bearer " + accessToken);
      dispatch({
        type: SET_PTP_CURRENT_USER,
        payload: accessToken,
      });
    }
  } catch (err) {
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};
export const getEnvConfig = (payload) => async (dispatch) => {
  try {
    const url = `${NODEURL}/api/users/config`;
    let res;
    res = await axios.get(url);

    let data = res.data;
    let decryptData = {};
    decryptData.REACT_APP_PTP_APPURL = decryptString(data.REACT_APP_PTP_APPURL);
    decryptData.REACT_APP_PTP_AUTHURL = decryptString(data.REACT_APP_PTP_AUTHURL);
    decryptData.REACT_APP_PTP_CLIENTID = decryptString(data.REACT_APP_PTP_CLIENTID);
    decryptData.REACT_APP_PTP_SECRET = decryptString(data.REACT_APP_PTP_SECRET);   
    decryptData.REACT_APP_PTP_ASKQUESTION_URL = decryptString(data.REACT_APP_PTP_ASKQUESTION_URL);       
    dispatch({
      type: FETCH_CONFIG,
      payload: decryptData,
    });
    // let { REACT_APP_PTP_APPURL, REACT_APP_PTP_AUTHURL } = res.data;
    // sessionStorage.setItem("PTP_AUTHURL", REACT_APP_PTP_AUTHURL);
    // sessionStorage.setItem("PTP_APPURL", REACT_APP_PTP_APPURL);
  } catch (error) {
    handleError(error, dispatch);
  }
};

function decryptString(val) {
  let ENC_KEY = process.env.REACT_APP_ENC_KEY;
  let ENC_IV = process.env.REACT_APP_ENC_IV;
  const encSecretKeys = CryptoJS.SHA256(ENC_KEY)
    .toString(CryptoJS.enc.Base64)
    .substring(0, 32);  
  let key = CryptoJS.enc.Utf8.parse(encSecretKeys);
  let iv = CryptoJS.enc.Utf8.parse(ENC_IV);

  let decryptedWA = CryptoJS.AES.decrypt(val, key, { iv: iv });

  let decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);
  return decryptedUtf8;
}
export function setupAxiosInterceptors(token) {
  axios.interceptors.request.use((config) => {
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  });
}

export async function userLogout(dispatch) {
  //if (sessionStorage.getItem("jwtTokenPTP") && sessionStorage.getItem("jwtTokenPTP") !== null) {

  sessionStorage.removeItem("jwtTokenPTP");
  sessionStorage.removeItem("jwtToken");
  sessionStorage.removeItem("userinfo");

  setJWTToken(false);
  window.history.replaceState(null, null, "/");
  window.location.href = "/";

  //}

};


