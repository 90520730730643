import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserList } from "../../../actions/index";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import classnames from "classnames";
import { PatternFormat, NumericFormat } from "react-number-format";
import * as routes from "../../../constants/routes";
import {
    AddUser, EditUser
} from "../../../actions/index";
import { alertService } from "./../../GlobalComponent/alertService";

class AddEditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelShow: true,
            uid: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            EmailHelperText: "",
            PhoneHelperText: "",
        };
    }
    componentDidMount() {
        this.storeUserValues();
    }
    componentDidUpdate(prevProps) {
        if (
            this.props.EditElement !== null &&
            this.props.EditElement !== prevProps.EditElement &&
            this.props.mode === "Edit"
        ) {

            this.storeUserValues();
        }
        if (this.props.errors && this.props.errors !== prevProps.errors) {
            alertService.error(this.props.errors.errorMessage, { autoClose: true });
            this.setState({ email: '' });
            //this.setState({ AddPatientModal: true });
        }
        if (this.props.userAdd && this.props.userAdd !== prevProps.userAdd) {
            alertService.success("Invitation Sent Successfully.", { autoClose: true });
            this.props.handleClose();
            this.props.fetchUserList();
        }
        if (this.props.userEdit && this.props.userEdit !== prevProps.userEdit) {
            alertService.success("User updated successfully.", { autoClose: true });
            this.props.handleClose();
            this.props.fetchUserList();
        }

    }
    async storeUserValues() {
        if (
            this.props.mode === "Edit" &&
            this.props.EditElement.uid !== null &&
            this.props.EditElement.uid !== undefined
        ) {
            let userInfo = this.props.EditElement;

            this.setState({
                uid: userInfo.uid,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                email: userInfo.email,
                phoneNumber: userInfo.phoneNumber,
            });
        } else {

            this.setState({
                uid: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
            });

        }
    }
    onChangeValueHandler = (e) => {
        let value = e.target.value.trim();
        this.setState({
            [e.target.id]: value.trim(),
            EmailHelperText: "",
        });
    };
    ValidateData = async (e) => {
        this.setState({
            EmailHelperText: "",
            PhoneHelperText: "",
        });
        var validEmailRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!this.state.email.match(validEmailRegex)) {
            this.setState({ EmailHelperText: "Invalid email" });
            return false;
        }
        var validPhoneRegex = /^\d{10}$/;
        if (!this.state.phoneNumber.replace(/\W/g, "").match(validPhoneRegex)) {
            this.setState({ PhoneHelperText: "Invalid phone number" });
            return false;
        }
        let payload = {
            uuid: this.state.uid,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phoneNumber: this.state.phoneNumber,
        };

        if (this.props.mode === "Add") {
            //await this.AddUserHandler(payload);
            this.props.AddUser(payload);
        } else {
            this.props.EditUser(payload);
        }

    };
    render() {
        return (
            <Modal show={this.props.AddEditUserModal}  >
                <Modal.Header closeButton>
                    <Modal.Title className="font-18 font-quicksand">Invite User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <small className="d-block text-right">IMPORTANT <span className="text-danger">*</span> are required fields.</small>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="mt-2">First Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" className={classnames("form-control font-18")} placeholder="First Name" id="firstName" value={this.state.firstName}
                                onChange={(e) => this.onChangeValueHandler(e)} />

                            <Form.Label className="mt-2">Last Name <span className="text-danger">*</span> </Form.Label>
                            <Form.Control type="text" className={classnames("form-control font-18")} placeholder="Last Name" id="lastName" value={this.state.lastName}
                                onChange={(e) => this.onChangeValueHandler(e)} />

                            <Form.Label className="mt-2">Email Address <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="email" className={classnames("form-control font-18", {
                                "is-invalid": this.state.EmailHelperText,
                            })} placeholder="Email Address" id="email" value={this.state.email}
                                onChange={(e) => this.onChangeValueHandler(e)} disabled={this.props.mode === 'Edit' ? true : false} />
                            {this.state.EmailHelperText && (
                                <div className="invalid-feedback">
                                    {this.state.EmailHelperText}
                                </div>
                            )}
                            <Form.Label className="mt-2">Phone Number <span className="text-danger">*</span></Form.Label>
                            <PatternFormat
                                format="(###) ###-####"
                                mask="_"
                                className={classnames("form-control font-18 h-auto", {
                                    "is-invalid": this.state.PhoneHelperText,
                                })}
                                placeholder="Phone Number"
                                id="phoneNumber"
                                name="phoneNumber"
                                onChange={(e) => this.onChangeValueHandler(e)}
                                value={this.state.phoneNumber}
                            />
                            {this.state.PhoneHelperText && (
                                <div className="invalid-feedback mt-3">
                                    {this.state.PhoneHelperText}
                                </div>
                            )}
                            
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outlined" className="btn btn-dark text-uppercase mr-3" onClick={(e) => {
                        e.preventDefault();
                        this.ValidateData(e);
                    }} disabled={this.state.firstName == '' || this.state.lastName == '' || this.state.email == "" || this.state.phoneNumber == "" ? true : false}>
                        Submit
                    </Button>
                    <Button className="btn btn-primary px-4 ml-0" onClick={(e) => {
                        this.props.handleClose();
                    }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        //provider: state.provider.providerList,
        currentUser: state.security.user,
        errors: state.errors,
        userAdd: state.user.userAdd,
        userEdit: state.user.userEdit,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AddUser,
            EditUser,
            fetchUserList,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
