import React from 'react';
import { Modal } from 'react-bootstrap';


class ConfirmationDeleteModal extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            show: true,
            title:'Delete',
            description:'Are you sure you want to delete this?',
            confirmButtonText:'Yes',
            closeButtonText:'No'
        };
    }

    componentWillMount() {             
        this.setState({
            title: (this.props.title) ? this.props.title: 'Delete',
            description:  (this.props.description) ? this.props.description: 'Are you sure you want to delete this?',
            confirmButtonText: (this.props.confirmButtonText) ? this.props.confirmButtonText: 'Yes',
            closeButtonText: (this.props.closeButtonText) ? this.props.closeButtonText: 'No'
        })
    }

    render() {
        return (
            <div>
                <Modal show={this.props.showDeletePopup} onHide={this.props.closePopup} backdrop={'static'} keyboard={false}>
                    <Modal.Header>
                        <Modal.Title className="font-18 font-quicksand">{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p><b>{this.state.description}</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="outlined" onClick={this.props.closePopup} className="btn btn-dark text-uppercase mr-3" data-dismiss="modal">{this.state.closeButtonText}</button>
                        <button type="button"  onClick={() => this.props.confirmeDelete(this.props.recordId)} className="btn btn-primary px-4 ml-0"> {this.state.confirmButtonText}</button>
                    </Modal.Footer>
                </Modal>
            </div>


        );
    }
}
export default ConfirmationDeleteModal;
