
import React, { Component } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Form from 'react-bootstrap/Form';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import Progress from "react-progress-2";
import moment from "moment";
import { fetchApplicationList, askQuestion, addConversation, fetchConvesationHistory, selectChatId, setNewChat } from "../../../actions/index";
import ResultThread from './ResultThread'
import ChatHistory from './ChatHistory'
import * as routes from "../../../constants/routes";
import classnames from "classnames";
class DashboardResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      lastQuestion: "",
      result: {},
      application_id: "",
      previousResult: "",
      applicatonList: {},
      questionThreads: [],
      applicationName: "",
      conversationId: "",
      showTopScroll: false,
      showBottomScroll: false,
      ApplicationHelperText: "",
    };
    this.divRef = React.createRef();
  }

  async componentDidMount() {
    let currentUser = this.props.currentUser
    if (currentUser === '' || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
      return false;
    }
    let location = this.props.location;
    if (location && location.hash) {
      let chatId = location.hash;
      chatId = chatId.replace("#", '');
      this.setState({ conversationId: chatId });
    }

    await this.props.fetchApplicationList();    
    //this.props.fetchConvesationHostiry();
    this.setState({
      applicatonList: this.props.applicatonList,
      application_id: this.props.applicationId
    });    
    if (this.props.questionObj) {
      this.setState({
        application_id: this.props.questionObj.application_id,
        question: this.props.questionObj.question,
        token: this.props.questionObj.token,
      });

      if (this.props.questionObj.application_id !== '') {
        let selectedApplication = this.props.applicatonList.filter((val) => {
          return val.id === this.props.questionObj.application_id
        });
        this.setState({ applicationName: selectedApplication[0].name });
        setTimeout(async () => {
          let converstaionName = this.state.applicationName + ' ' + moment().unix();
          await this.props.addConversation(this.state.conversationId, converstaionName, this.state.application_id, '', '');
          this.props.fetchConvesationHistory();
        }, 200);
      }
    }
    if (this.props.selectedChatId) {
      this.setState({ conversationId: this.props.selectedChatId });

    }

  }

  onScrollDefault() {


    this.divRef.current.scrollIntoView({
      block: "end", inline: "nearest",
      behavior: "smooth"
    });
    let el = this.divRef.current;
    let rect = el.getBoundingClientRect();
    this.setState({ "scrollTop": rect.top, "scrollBottom": rect.bottom });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.applicatonList && prevProps.applicatonList !== this.props.applicatonList) {
      this.setState({ applicatonList: this.props.applicatonList });
    }

    if (this.props.applicatonId && prevProps.applicatonId !== this.props.applicatonId) {
      //alert(this.props.applicationId);
    }

    if (this.props.askResults && prevProps.askResults !== this.props.askResults) {

      // setTimeout(() => {
      //   console.log("this.state.result::::", this.state.result);
      // }, 500);
      let questionThread =
      {
        lastQuestion: this.state.question,
        result: this.props.askResults
      };

      let questionThreads = this.state.questionThreads
      questionThreads.push(questionThread);
      this.setState({ questionThreads: questionThreads });
      this.setState({ question: "" });
      let converstaionName = this.state.applicationName + ' ' + moment().unix();
      let answer = JSON.stringify(this.props.askResults);
      await this.props.addConversation(this.state.conversationId, converstaionName, this.state.application_id, this.state.question, answer);
      this.props.fetchConvesationHistory();
      this.onScrollDefault();
    }

    if (this.props.converationAdd && prevProps.converationAdd !== this.props.converationAdd) {
      if (this.props.converationAdd && this.props.converationAdd.id && this.props.converationAdd.id != '') {
        this.setState({ "conversationId": this.props.converationAdd.id });        
        this.props.selectChatId(this.props.converationAdd.id);
      }
      if (this.props.converationAdd.id) {
        this.props.history.push(routes.DASHBOARDRESULT + '#' + this.props.converationAdd.id);
      }
    }

    if (this.props.chatHistoryInfo && this.props.chatHistoryInfo !== prevProps.chatHistoryInfo) {
      let chatHistoryInfo = this.props.chatHistoryInfo;
      let questionThreads = [];
      chatHistoryInfo.ConversationDetails.forEach(ele => {
        if (ele.question !== '') {
          let questionThread =
          {
            lastQuestion: ele.question,
            result: JSON.parse(ele.answer)
          };
          questionThreads.push(questionThread);
        }
      });
      //console.log("questionThreads:::",questionThreads);     

      setTimeout(() => {
        if (this.state.applicatonList && Object.keys(this.state.applicatonList).length > 0) {
          let application = this.state.applicatonList.filter((val) => { return val.id === chatHistoryInfo.application_id });

          this.setState({
            application_id: chatHistoryInfo.application_id,
            questionThreads: questionThreads,
            token: application[0].token,
            ApplicationHelperText: "",
            showBottomScroll: false,
            showTopScroll: false
          });
          setTimeout(() => {
            this.onScrollDefault();

          }, 300)
        }
      }, 500);


    }

    if (this.props.chatHistoryDelete && prevProps.chatHistoryDelete !== this.props.chatHistoryDelete) {
      this.setState({
        application_id: "",
        token: '',
        applicationName: "",
        conversationId: "",
      });
      //this.props.askQuestion('', '', '');
      this.setState({ questionThreads: [] });
    }
    if (this.props.selectedChatId && prevProps.selectedChatId !== this.props.selectedChatId) {
      this.setState({ conversationId: this.props.selectedChatId });
      this.props.setNewChat(false);
      if (prevProps.selectedChatId != 0) {
        this.setState({ question: '' });
      }
    }
    if (prevProps.newChatSet !== this.props.newChatSet) {
      if (this.props.newChatSet) {
        this.setState({ application_id: '', questionThreads: [], question: '' });
        this.setState({
          showBottomScroll: false,
          showTopScroll: false
        })
      }
    }


  }
  onSelectApplication = async (e) => {
    this.setState({ ApplicationHelperText: "" });
    let token = e.target[e.target.selectedIndex].getAttribute('data-token');
    let value = e.target.value;
    let index = e.nativeEvent.target.selectedIndex;
    this.setState({
      [e.target.id]: value,
      token: token,
      applicationName: e.nativeEvent.target[index].text,
      conversationId: "",
      question: ''
    });
    //this.props.askQuestion('', '', '');
    this.setState({ questionThreads: [] });

    setTimeout(async () => {
      if (this.state.application_id !== '') {
        let converstaionName = this.state.applicationName + ' ' + moment().unix();

        await this.props.addConversation(this.state.conversationId, converstaionName, this.state.application_id, '', '');
        this.props.fetchConvesationHistory();

        this.setState({
          showBottomScroll: false,
          showTopScroll: false
        })
      }
      //this.props.selectChatId(this.state.application_id);
    }, 200);
  };
  onChangeValueHandler = (e) => {
    let value = e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  };
  sendQuestion = () => {
    let applicationId = this.state.application_id;
    if (applicationId == '') {
      this.setState({ ApplicationHelperText: "Please select application" });
    } else {
      let question = this.state.question;
      question = question.trim();
      let token = this.state.token;
      if (!Progress.isVisible() && question !== '') {
        this.props.askQuestion(applicationId, question, token);
      }
    }
  }

  exportToPDF() {
    let applicationName = '';
    let seltApplication = this.state.applicatonList.filter((app) => { return app.id === this.state.application_id });
    applicationName = seltApplication[0].name;
    //const doc = new jsPDF();
    let questionThreads = this.state.questionThreads;

    var doc = new jsPDF('l');

    let finalx = 15;
    let finalY = 5;
    let lineSpace = 10;
    let fontSize = 11;
    var pageHeight = 190;
    doc.setFontSize(fontSize);
    let logo = "/images/Logo2.png";
    doc.addImage(logo, 'JPEG', finalx, finalY, 80, 20);
    finalY = finalY + 30
    doc.setFont(undefined, 'bold');
    doc.setFontSize(15);
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.text(pageWidth / 2, finalY, applicationName, { align: 'center' });
    //const textWidth = doc.getTextWidth(applicationName);
    //doc.line(pageWidth / 2, finalY + 1,textWidth, finalY + 1)

    finalY = finalY + 5;
    doc.setFontSize(fontSize);
    questionThreads.forEach((rec) => {
      finalY = finalY + lineSpace;
      if (finalY >= pageHeight) {
        doc.addPage();
        finalY = 10 // Restart height position
      }
      doc.setFont(undefined, 'bold')
      let userlogo = "/images/profile.png";
      doc.addImage(userlogo, 'JPEG', finalx, finalY, 7, 7);
      finalY = finalY + 5;
      doc.text(finalx + 10, finalY, rec.lastQuestion);
      if (rec.result.type === 'table') {
        let cols = rec.result.data.columns
        let rows = rec.result.data.records;
        let columns = [];
        cols.forEach((col) => {
          let obj = {
            header: col, dataKey: col
          }
          columns.push(obj);
        });
        rows.forEach((row, index) => {
          rows[index][cols[index]] = row[index];

        });
        finalY = finalY + 7;
        // let bllogo = "images/logo-icon.png";
        // doc.addImage(bllogo, 'JPEG', finalx, finalY, 7, 7);
        // finalY = finalY+5;
        autoTable(doc, {
          //columnStyles: { europe: { halign: 'center' } }, // European countries centered
          body: rows,
          columns: columns,
          startX: 25,
          startY: finalY,
          margin: { vertical: 3, horizontal: 25 },
          pageBreak: 'auto',
          rowPageBreak: 'avoid',
          styles: {
            fontSize: fontSize,
          },
          headStyles: { fillColor: [26, 71, 185] },
          didDrawPage: function (data) {

            // Header
            //doc.setFontSize(fontSize);
            //doc.setTextColor(40);
            //doc.text("Report", finalx, finalY);
            // let bllogo = "images/logo-icon.png";
            // doc.addImage(bllogo, 'JPEG', finalx, finalY, 7, 7);
            // finalY = finalY + 5;
            // Footer
            // var str = "Page " + doc.internal.getNumberOfPages();

            // doc.setFontSize(10);

            // // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            // var pageSize = doc.internal.pageSize;
            // var pageHeight = pageSize.height
            //   ? pageSize.height
            //   : pageSize.getHeight();
            // doc.text(str, data.settings.margin.left, pageHeight - 10);
          }
        });
        finalY = doc.lastAutoTable.finalY;
      }
      else {
        doc.setFont(undefined, 'normal')
        let ans = rec.result.data;
        finalY = finalY + lineSpace;
        if (finalY >= pageHeight) {
          doc.addPage();
          finalY = 10 // Restart height position
        }
        doc.text(finalx + 10, finalY, ans);
      }
    });
    let fileName = `${applicationName.replace(" ", "")}_${moment().format('MMDDYYYYHHmmss')}.pdf`;
    doc.save(fileName);
  }
  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.sendQuestion();
    }
  }
  handlerNewChat() {
    this.props.history.push(routes.DASHBOARDRESULT);
    this.setState({ application_id: '', questionThreads: [] });
    this.setState({
      showBottomScroll: false,
      showTopScroll: false
    })
  }
  handlerChatListClick(id) {
    //this.props.selectChatId(id);
    this.props.history.push(routes.DASHBOARDRESULT);
    this.setState({
      showBottomScroll: false,
      showTopScroll: false
    })
  }
  scrollToTop() {
    //const divRef = useRef(null);
    let el = this.divRef.current;
    let rect = el.getBoundingClientRect();
    this.setState({ "scrollTop": rect.top, "scrollBottom": rect.bottom });
    this.divRef.current.scrollIntoView({
      top: -1,
      behavior: "smooth"
    });
  };
  scrollToBottom() {
    //const divRef = useRef(null);
    let el = this.divRef.current;
    let rect = el.getBoundingClientRect();
    //console.log("rect bottom:::", rect);
    this.setState({ "scrollTop": rect.top, "scrollBottom": rect.bottom });
    this.divRef.current.scrollIntoView({
      block: "end", inline: "nearest",
      behavior: "smooth"
    });
  };
  render() {
    const handleScroll = event => {
      this.setState({
        showBottomScroll: true,
        showTopScroll: true
      })
      // console.log("scrollTop:::",event.currentTarget.scrollTop);      
      // console.log("clientHeight:::",event.currentTarget.clientHeight);
      // console.log("scrollHeight:::",event.currentTarget.scrollHeight);

      //console.log("scroll:::",event.currentTarget.scrollTop);
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (Math.ceil(scrollTop + clientHeight) >= (scrollHeight - 2)) {

        //console.log("reached bottom hook");
        //setIsBottom(true);
        this.setState({
          showBottomScroll: false
        });
      }

      if (scrollTop <= 0) {
        this.setState({
          showTopScroll: false
        });
      }

    };
    return (
      <div className="grow-1 flex flex-row">
        {/* <ChatHistory handlerChatListClick={(id) => this.handlerChatListClick(id)} handlerNewChat={() => this.handlerNewChat()}></ChatHistory> */}
        <div className="flex flex-col w-100">
          <div className="bg-light-2 py-2 d-flex">
            <div className="max-w-768 mx-auto w-100 d-flex gap-18 chat-interface flex-wrap px-1 gap-sm-10">
              <label className="font-20 font-bold font-blue m-0">Application:</label>
              {this.state.applicatonList &&
                <div className=" grow-1">
                  <Form.Select className={classnames("p-2 rounded-8 w-100 select-application", {
                    "is-invalid": this.state.ApplicationHelperText,
                  })} aria-label="Default select example" value={this.state.application_id} onChange={(e) => this.onSelectApplication(e)} id="application_id">
                    <option value="">Select Application</option>
                    {this.state.applicatonList.length > 0 && this.state.applicatonList.map((item) => {
                      return <option data-token={item.token} value={item.id}>{item.name}</option>;
                    })}
                  </Form.Select>
                  <div className="invalid-feedback">
                    {this.state.ApplicationHelperText}
                  </div>
                </div>
              }
              {this.state.questionThreads.length > 0 &&
                <a className="ml-auto" href="javascript:void(0);" onClick={() => { this.exportToPDF() }}><img src="/images/picture_as_pdf_black.svg" className="" alt="send" width={30} /></a>
              }
            </div>
          </div>
          <div className="grow-1 flex flex-col middle-section-scroll position-relative" onScroll={handleScroll}>


            <div id='pdfcontent' className="w-100 pb-2" ref={this.divRef}>

              {this.state.questionThreads.length > 0 && this.state.questionThreads.map((thread, i) => {
                return <ResultThread lastQuestion={thread.lastQuestion} result={thread.result} />
              })
              }
            </div>
          </div>
          <div className="w-100 mx-auto position-relative">

            {this.state.showTopScroll &&
              <div className="up-down-arrow position-absolute cursor up" onClick={() => this.scrollToTop()}><img src="/images/arrow.svg" className="" alt="send" /></div>
            }
            {this.state.showBottomScroll &&
              <div className="up-down-arrow position-absolute cursor" onClick={() => this.scrollToBottom()}><img src="/images/arrow.svg" className="" alt="send" /></div>
            }


          </div>
          <div className="max-w-768 mx-auto w-full px-2 mb-2 mt-auto">
            <div className="enter-text flex bg-light-1">
              <input type="" id="question" value={this.state.question} className="grow-1" placeholder="Enter an Ask My Data question here" onChange={(e) => this.onChangeValueHandler(e)} onKeyDown={(e) => this.handleKeyDown(e)} />
              <button className="enter-text-button" disabled={!this.state.question ? true : false} onClick={() => this.sendQuestion()}>
                <img src="/images/send_light.png" className="" alt="send" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    applicatonList: state.dashboard.applicatonList,
    applicationId: state.dashboard.applicationId,
    askResults: state.dashboard.askResults,
    questionObj: state.dashboard.questionObj,
    currentUser: state.security.user,
    converationAdd: state.dashboard.converationAdd,
    chatHistoryInfo: state.dashboard.chatHistoryInfo,
    chatHistoryDelete: state.dashboard.chatHistoryDelete,
    selectedChatId: state.dashboard.selectedChatId,
    newChatSet: state.dashboard.newChatSet,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      fetchApplicationList,
      askQuestion,
      addConversation,
      fetchConvesationHistory,
      selectChatId,
      setNewChat
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardResult);