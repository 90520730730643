
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getChatHistoryDetail, updateConversationLabel, deleteConversation,selectChatId,isEditChatTitle } from "../../../actions/index";
import ConfirmationDeleteModal from '../../GlobalComponent/ConfirmationDeleteModal';
class ChatLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatList: [],
      labelEdit: false,
      chat: '',
      chatLabel: '',
      modalType: '',
      selected_chat_id: '',
    };
    this.input = React.createRef();
  }

  componentDidMount() {    
    this.setState({ chat: this.props.chat, selected_chat_id: this.props.selected_chat_id });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.chatHistoryDelete && this.props.chatHistoryDelete !== prevProps.chatHistoryDelete) {
      this.setState({ modalType: false });
      //await this.props.fetchConvesationHistory();
    }
    if (this.props.selected_chat_id && this.props.selected_chat_id !== prevProps.selected_chat_id) {
      this.setState({ selected_chat_id: this.props.selected_chat_id, labelEdit: false });
    }
    if(this.props.selectedChatId!==prevProps.selectedChatId){
      this.setState({ selected_chat_id:this.props.selectedChatId});
    }
    if (prevProps.newChatSet !== this.props.newChatSet) {
      if (this.props.newChatSet) {
        this.setState({ selected_chat_id: ''});
      }
    }
  }
  getChatHistory(chat) {
    
    if(this.state.selected_chat_id!=chat.id){     
      this.props.isEditChatTitle('close');
    }
    this.props.selectChatId(chat.id);
    //this.props.history.push(routes.DASHBOARDRESULT);
    if (window.location.pathname == '/dashboard') {
      this.props.handlerChatListClick(chat.id);
    }
    this.props.handlerChatListClick(chat.id);
    //this.props.getChatHistoryDetail(chat.id);
  }
  editConversation() {
    this.setState({ labelEdit: true, chatLabel: this.state.chat.name });
    this.props.isEditChatTitle('open');
    setTimeout(() => {
      this.input.current.focus();
    }, 200);
  }
  deleteConversation(id, type = "DELETE") {
    this.setState({
      modalType: type,
      selectedId: id
    });
    this.props.isEditChatTitle('close');
  }
  handlerFocusOut(e) {
    //console.log("e.target:::", e.target);
    //console.log(this.state.chat.id + ':::' + this.state.selected_chat_id);
    //this.setState({ labelEdit: false });
    //this.props.updateConversationLabel(this.state.chat);
  }
  handlerChangeLabel(e) {
    let chat = this.state.chat;
    //chat.name = e.target.value;
    this.setState({ chatLabel: e.target.value });
  }
  closeModal() {
    this.setState({
      modalType: '',
    });
    //this.fetchRecords();
  }
  deleteRecord(id) {
    this.props.deleteConversation(id);
  }
  saveConversation(id) {
    let chat = this.state.chat;
    chat.name = this.state.chatLabel;
    this.setState({ chat: chat, labelEdit: false });
    this.props.updateConversationLabel(this.state.chat);
    this.props.isEditChatTitle('close');
  }
  cancelEdit() {
    this.setState({ chatLabel: this.state.chat.name, labelEdit: false });
    this.props.isEditChatTitle('close');
  }
  render() {
    return (
      <>
        {this.state.chat &&
          <small className={`enter-text text-left p-2 flex align-items-center mb-2 mr-3 ${this.state.selected_chat_id == this.state.chat.id && 'active'}`} onBlur={(e) => this.handlerFocusOut(e)}>
            <button onClick={() => { this.getChatHistory(this.state.chat) }} className="grow-1 d-flex text-left align-items-center" title={this.state.chat.name}>
              {/* <img src="images/chat_bubble_outline_black.png" className="pr-1 mt-1" alt="send" width={20} /> */}
              {!this.state.labelEdit &&
                <span ref={this.input} className="line-clamp-2" >{this.state.chat.name}</span>
              }
              {this.state.labelEdit &&
                <input ref={this.input} className="p-0 small w-100 pl-1 py-0" style={{ fontSize: '16px' }} contentEditable={this.state.labelEdit} value={this.state.chatLabel} onChange={(e) => this.handlerChangeLabel(e)} />
              }
            </button>
            {!this.state.labelEdit &&
              <>
                <button className="w-20px" onClick={() => this.editConversation(this.state.chat.id)}>
                  <img src="/images/edit_black.png" className="pl-1 mt-1" alt="send" width={20} />
                </button>
                <button className="w-20px" onClick={() => this.deleteConversation(this.state.chat.id)}>
                  <img src="/images/delete_red.svg" className="pl-1 mt-1" alt="send" width={20} />
                </button>
              </>
            }
            {this.state.labelEdit &&
              <>
                <button id="save" className="w-20px" onClick={() => this.saveConversation(this.state.chat.id)}>
                  <img src="/images/save_black.png" className="pl-1 mt-1" alt="send" width={20} />
                </button>
                <button id="cacel" className="w-20px" onClick={() => this.cancelEdit(this.state.chat.id)}>
                  <img src="/images/cancel_black.png" className="pl-1 mt-1" alt="send" width={20} />
                </button>
              </>
            }
            <button onClick={() => { this.getChatHistory(this.state.chat) }} className="chat-hide-show">
              <img src="/images/chat_bubble_outline_black.png" className="" alt="send" width={20} />
            </button>
          </small>
        }
        {this.state.modalType == 'DELETE' ?
          <ConfirmationDeleteModal confirmeDelete={(id) => this.deleteRecord(id)} closePopup={this.closeModal.bind(this)} showDeletePopup={(this.state.modalType == 'DELETE') ? true : false} recordId={this.state.selectedId}></ConfirmationDeleteModal> : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    chatHistoryDelete: state.dashboard.chatHistoryDelete,
    selectedChatId: state.dashboard.selectedChatId,
    newChatSet: state.dashboard.newChatSet
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      getChatHistoryDetail,
      updateConversationLabel,
      deleteConversation,
      selectChatId,
      isEditChatTitle,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatLabel);