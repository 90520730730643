import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { auth } from '../../../firebase';
import { signOut } from 'firebase/auth';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userLogout } from "../../../actions/index";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import * as routes from "../../../constants/routes";
import ChatHistory from '../../mainTabs/dashboard/ChatHistory';

export default function LeftNav(props) {
  const location = useLocation();
  //tabs
  const history = useHistory();

  const handlerNewChat = () => {
    //props.setQuestion('', '', '');
    history.push(routes.DASHBOARDRESULT);
  }
  const handlerChatListClick = (id) => {
    //this.props.setQuestion('', '', '');
    history.push(routes.DASHBOARDRESULT+'#'+id);
  }

  let chatId = '';
  if(location.hash){
    chatId =  location.hash;
    chatId = chatId.replace("#",'');
  }
  
  return (
    <React.Fragment>
      {(location.pathname == '/dashboard' || location.pathname == '/dashboardresult/') &&
        <ChatHistory chatId = {chatId} handlerChatListClick={(id) => handlerChatListClick(id)} handlerNewChat={() => handlerNewChat()}></ChatHistory>
      }
    </React.Fragment>
  );
}