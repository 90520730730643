import React, { useState, useEffect } from "react";
import { useRef } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { auth } from '../../../firebase';
import { signOut } from 'firebase/auth';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userLogout } from "../../../actions/index";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";

export default function Header(props) {
  const wrapperRef = useRef(null);
  //tabs
  const [value, setValue] = useState(0);
  const [isNavExpanded, setIsNavExpanded] = useState(false);


  const { pathname } = useLocation();
  useEffect(() => {

    if (window.location.pathname === "/patients" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/users" && value !== 2) {
      setValue(1);
    } else if (window.location.pathname === "/logs" && value !== 3) {
      setValue(2);
    } else if (window.location.pathname === "/kit" && value !== 1) {
      setValue(3);
    } else if (window.location.pathname === "/help" && value !== 1) {
      setValue(4);
    }

  }, [value]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        closeNav();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const closeNav = () => {
    setIsNavExpanded(false);
  }

  const user = useSelector((state) => state.security.user);
  const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
  let displayName = userinfo.username;
  const logout = () => {
    signOut(auth).then((res) => {
      userLogout();
    }).catch((error) => {

    });
  };
  const toggleMenu = (isExpand) => {
    setIsNavExpanded(isExpand);
  }
  return (
    <React.Fragment>
      <nav className="header-dark z-index-9 bg-white border-bottom">
        <div className="mx-auto ps-2 pr-2 py-1">
          <div className="relative flex items-center justify-between menu-hide-logo-position">
            <Link className="me-auto" to="/dashboard">
              <img src="/images/Logo2.png" className="logo py-0" alt="Your Company" width={250} />
            </Link>
            <Navbar collapseOnSelect expand="sm" className="bg-body-tertiary px-2 navbar-nav py-0" onToggle={() => toggleMenu(!isNavExpanded)}
              expanded={isNavExpanded} ref={wrapperRef}>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" className="p-2">
                <Nav.Link as={Link} eventKey="dashboard" className={classNames("text-dark mx-2 my-2 my-md-0 py-0", { 'active': (pathname === '/dashboardresult' || pathname === '/dashboard') })} to="/dashboard"
                >
                  Dashboard
                </Nav.Link>

                {user.admin &&
                  <Nav.Link
                    as={Link}
                    eventKey="users"
                    className={classNames("text-dark mx-2 my-2 my-md-0 py-0", { 'active': pathname === '/users' })}
                    to="/users"
                  >
                    Users
                  </Nav.Link>
                }
              </Navbar.Collapse>
            </Navbar>

            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic" className="px-0 py-2 no-after">
                <button type="button" className="relative d-flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-0 focus:ring-transprent focus:ring-offset-2 focus:ring-offset-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                  <img className="h-8 w-8 rounded-full profile-square" src="/images/profile.png" alt="" />
                </button>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-theme">
                <Dropdown.Item className="my-2 mx-2" active>{displayName}</Dropdown.Item>
                <Dropdown.Item className="my-2 mx-2" onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}