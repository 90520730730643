import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";



export default function Header(props) {
  const dispatch = useDispatch();

  //tabs
  const [value, setValue] = useState(0);
  const handleChange = (e, value) => {
    setValue(value);
  };

  useEffect(() => {
    if (window.location.pathname === "/login" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/register" && value !== 1) {
      setValue(1);
    }
  }, [value]);

  return (
    <React.Fragment>      
    </React.Fragment>
  );
}
//<div className={classes.toolbarMargin} />
