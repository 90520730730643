import React, { Component } from "react";
import { signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { auth, googleProvider } from '../../../firebase';
import jwt_decode from "jwt-decode";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCurrentUser, ptpAuthorization, getEnvConfig } from "../../../actions/securityActions";
import * as routes from "../../../constants/routes";
import Progress from "react-progress-2";
import { fetchUserByUid, FETCH_USER, createUser } from "../../../actions/index";

export const PTP_CLIENTID = process.env.REACT_APP_PTP_CLIENTID;
export const PTP_SECRET = process.env.REACT_APP_PTP_SECRET;



class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
      validLoginInfo: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    //this.signIn();
    if (Object.keys(this.props.currentUser).length > 0) {
      //console.log("token:::", this.props.ptpuser);
      this.props.history.push(routes.DASHBOARD);
    }

  }
  async componentDidUpdate(prevProps) {
    if (this.props.security.user && prevProps.security.user != this.props.security.user) {
      await this.props.getEnvConfig();
      //
    }
    if (this.props.ptpuser && prevProps.ptpuser != this.props.ptpuser) {

      let accessToken = sessionStorage.getItem("jwtToken");;

      this.props.setCurrentUser(accessToken);
      if(routes.DASHBOARD!==window.location.pathname){
        this.props.history.push(routes.DASHBOARD);
      }
    }

    if (this.props.config && prevProps.config != this.props.config) {
      let clientId = this.props.config.REACT_APP_PTP_CLIENTID;
      let secret = this.props.config.REACT_APP_PTP_SECRET;
      let payload = {
        "clientId": clientId,
        "secret": secret
      }
      let url = this.props.config.REACT_APP_PTP_AUTHURL;
      this.props.ptpAuthorization(payload, url);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout((e) => {
      if (this.state.username == "" || this.state.password == "") {
        this.setState({ validLoginInfo: false });
      } else {
        this.setState({ validLoginInfo: true });
      }
      // console.log("validLoginInfo:::", this.state.validLoginInfo)
    }, 200);
  }

  onSubmit(e) {
    // this.props.setCurrentUser(token.credential);//
    e.preventDefault();
    let email = this.state.username;
    let password = this.state.password;
    signInWithEmailAndPassword(auth, email, password).then(async (res) => {
      let accessToken = res.user.accessToken;
      let userInfo = await this.props.fetchUserByUid(res.user.uid, "Bearer "+res.user.accessToken);
      let displayName = userInfo.data.first_name + ' ' + userInfo.data.last_name;
      sessionStorage.setItem("jwtToken", "Bearer " + accessToken);
      sessionStorage.setItem("userinfo", JSON.stringify({"username":displayName,"role":userInfo.data.role}));
      //sessionStorage.setItem("username", displayName);
      this.props.setCurrentUser(accessToken);
    })
      .catch((error) => {
        let errors = { errorMessage: "Invalid email or password" };
        this.setState({ errors: errors });
      });
  }
  signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider).then((res) => {
         let userInfo = {
          'email': res.user.email,
          'uuid': res.user.uid,
          'firstName': res.user.displayName,
          'phoneNumber': res.user.phoneNumber
        }
        this.props.createUser(userInfo)
        let accessToken = res.user.accessToken;
        sessionStorage.setItem("jwtToken", "Bearer " + accessToken);
        //sessionStorage.setItem("username", res.user.displayName);
        sessionStorage.setItem("userinfo", JSON.stringify({"username":res.user.displayName,"role":'client'}));
        this.props.setCurrentUser(accessToken);
      }).catch((error) => {
        let errors = { errorMessage: "Invalid email or password" };
        this.setState({ errors: errors });
      });
    } catch (err) {
      console.error(err);
    }
  };
  forgotPasswardHandler(){
    this.props.history.push(routes.FORGOTPASSWORD);
  }
  render() {
    const { errors } = this.state;
    return (
      <div className="login-bg position-relative">       
        <section className="user-section item-center" style={{'min-height':'100vh'}}>
          <div className="p-3 p-md-5 m-2 radius-12 shadow text-center bg-white">
            <figure className="brand-ui">
              <img src="/images/Logo2.png" alt="PTP" style={{ maxHeight: 191 }} />
            </figure>
            <h2 className="font-bold font-20 mb-0">Login</h2>
            <div>
              <div className="error-msg-holder">
                {errors.errorMessage && (
                  <div className="error text-danger mt-2">{errors.errorMessage}</div>
                )}
              </div>
              <form onSubmit={this.onSubmit} className="d-flex flex-column">
                <div className="form-card my-3">
                  <input type="text" autoComplete="off" className={classnames("form-control font-18", { "is-invalid": errors.username, })} placeholder="Email Address" name="username" value={this.state.username} onChange={this.onChange} />
                  {errors.username && (
                    <div className="invalid-feedback">{errors.username}</div>
                  )}
                </div>
                <div className="form-card">
                  <input type="password" autoComplete="off" className={classnames("form-control font-18", { "is-invalid": errors.password, })} placeholder="Password" name="password" value={this.state.password} onChange={this.onChange} />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <a href="javascript:void(0);" onClick={()=>this.forgotPasswardHandler()} className="text-muted mt-2 text-right">Forgot Password?</a>
                <button type="submit" disabled={!this.state.validLoginInfo} className={`btn bg-primary-theme text-white my-3 ${this.state.validLoginInfo ? "active" : ""}`}>Sign in</button>
              </form>
            </div>
            <div className="google-login">
              <button className="login-provider-button py-2 px-4 mx-auto rounded d-flex align-items-center gap-5" onClick={this.signInWithGoogle}>
                <img src="/images/google.png" alt="PTP" style={{ maxHeight: 20 }} />
                Sign in with Google
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  ptpuser: state.security.ptpuser,
  errors: state.errors,
  currentUser: state.security.user,
  config: state.security.config
});

export default connect(mapStateToProps, { setCurrentUser, ptpAuthorization, getEnvConfig, fetchUserByUid, createUser })(Login);
