import axios from "axios";
import Progress from "react-progress-2";
import { alertService } from "../components/GlobalComponent/alertService";
export const NODEURL = process.env.REACT_APP_NODE_URL;
export const PTP_AUTHURL = process.env.REACT_APP_PTP_AUTHURL;
export const PTP_APPURL = process.env.REACT_APP_PTP_APPURL;
export const PTP_ASKQUESTION_URL = process.env.REACT_APP_PTP_ASKQUESTION_URL;

export const GET_ERRORS = "GET_ERRORS";

//config module
export const FETCH_CONFIG = "FETCH_CONFIG";
export const SET_LEFT_MENU = "SET_LEFT_MENU";
export const SET_NEW_CHAT = "SET_NEW_CHAT";
//user module
export const FETCH_USERS = "FETCH_USERS"
export const USERS_DISABLED = "USERS_DISABLED"
export const USERS_ENABLED = "USERS_ENABLED"
export const USERS_ADD = "USERS_ADD"
export const FETCH_USER = "FETCH_USER"
export const USERS_EDIT = "USERS_EDIT"
export const USERS_DELETE = "USERS_DELETE"
export const USERS_RESET = "USERS_RESET"

// dashboard module
export const SET_APPLICATIONID = "SET_APPLICATIONID"
export const FETCH_ASKRESULTS = "FETCH_ASKRESULTS"
export const SET_QUESTION = "SET_QUESTION"
export const SELECT_CHAT_ID = "SELECT_CHAT_ID"
export const EDIT_CHAT_LABEL = "EDIT_CHAT_LABEL"


// Conversation module
export const SET_CONVERSATION = "SET_CONVERSATION"
export const DELETE_CHATHISTORY = "DELETE_CHATHISTORY"

// Chat History
export const SET_CHATHISTORY = "SET_CHATHISTORY"
export const GET_CHATHISTORYINFO = "GET_CHATHISTORYINFO"

export function getConfig() {
  const jwtToken = sessionStorage.getItem("jwtTokenPTP");
  const config = {
    headers: {
      Authorization: jwtToken,
    },
  };
  return config;
}

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    let url = new URL(config.url)
    let originURL = url.origin;
    let token = '';
    if (originURL === PTP_APPURL) {
      token = sessionStorage.getItem("jwtTokenPTP");
    }
    else if (originURL === NODEURL) {
      token = sessionStorage.getItem("jwtToken");
    }

    if (token) {
      config.headers["Authorization"] = token;
    }
    if (!config.loaderHide || config.loaderHide == "false") {
      Progress.show();
    }
    // config.headers['Content-Type'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    Progress.hide();
    return response;
  },
  function (error) {
    //const originalRequest = error.config;
    Progress.hide();

    if (error.response && error.response.status === 401) {

      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("jwtTokenPTP");
      sessionStorage.removeItem("userinfo");
      window.location.href = "/login";
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 500) {
      if (!error.response.data || !error.response.data.errorMessage) {
        alertService.error("Something went wrong. Please contact Administrator.", { autoClose: true });
      }
      return Promise.reject(error);
    }
    else if (error.response && error.response.status === 400) {
      if (!error.response.data || !error.response.data.errorMessage) {
        alertService.error("Something went wrong. Please contact Administrator.", { autoClose: true });
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export function handleError(error, dispatch) {
  if (error.response) {

    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  } else if (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { errorMessage: "Something went wrong. Please contact Administrator." },
    });
    // The request was made but no response was received
    //console.log("Error", error.message);
  } else if (error.message) {
    // Something happened in setting up the request that triggered an Error
    //console.log("Error", error.message);
  }
}

export function toggleModal(ms) {
  return {
    type: ms.type,
    payload: ms,
  };
}

export function setMenuToggle(menu) {
  return {
    type: SET_LEFT_MENU,
    payload: menu,
  };
}
export function setNewChat(newChat) {
  return {
    type: SET_NEW_CHAT,
    payload: newChat,
  };
}

