
import React, { Component } from "react";
import Select, { components } from 'react-select';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { fetchConvesationHistory, getChatHistoryDetail, userLogout, setMenuToggle, setNewChat, setQuestion, selectChatId, fetchApplicationList } from "../../../actions/index";
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase';
import * as routes from "../../../constants/routes";
import ChatLabel from './ChatLabel'
import { isMobile } from 'react-device-detect';

class ChatHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatList: [],
      contentEdit: [],
      menuExpand: true,
      newChat: true,
      selected_chat_id: '',
      isEditChatLabel: 'close',
    };

  }

  async componentDidMount() {
    this.props.fetchConvesationHistory();
    if (this.props.selectedChatId) {
      this.setState({ selected_chat_id: this.props.selectedChatId });
    }
    if (this.props.chatId) {
      this.props.getChatHistoryDetail(this.props.chatId);
      this.setState({ selected_chat_id: this.props.chatId });
    }
    await this.props.fetchApplicationList();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.chatHistoryGet && this.props.chatHistoryGet !== prevProps.chatHistoryGet) {
      this.setState({ chatList: [] });
      let chatList = this.props.chatHistoryGet.rows;
      setTimeout(() => {
        this.setState({ chatList: chatList });
      }, 50)

    }

    if (this.props.chatHistoryInfo && this.props.chatHistoryInfo !== prevProps.chatHistoryInfo) {
      //console.log("this.props.chatHistoryInfo::::", this.props.chatHistoryInfo);
    }
    if (this.props.chatHistoryDelete && this.props.chatHistoryDelete !== prevProps.chatHistoryDelete) {
      await this.props.fetchConvesationHistory();
    }
    if (this.props.selectedChatId !== prevProps.selectedChatId) {
      this.setState({ selected_chat_id: this.props.selectedChatId });
    }
    if (this.props.isEditChatLabel && this.props.isEditChatLabel !== prevProps.isEditChatLabel) {
      this.setState({ isEditChatLabel: this.props.isEditChatLabel });
    }
    if (this.props.applicatonList && prevProps.applicatonList !== this.props.applicatonList) {
      this.setState({ applicatonList: this.props.applicatonList });      
    }

  }

  getChatHistory(chat) {
    //this.props.history.push(routes.DASHBOARDRESULT);
    if (window.location.pathname == '/dashboard') {
      this.props.handlerChatListClick(chat.id);
    }
    this.props.getChatHistoryDetail(chat.id);
  }

  newChat() {
    this.props.handlerNewChat();
    this.props.setNewChat(true);
    this.props.setQuestion('', '', '');
    this.props.selectChatId('0');
    if (isMobile) {
      this.props.setMenuToggle(!this.state.menuExpand);
      this.setState({ menuExpand: !this.state.menuExpand });
    }
  }
  logout = () => {
    signOut(auth).then((res) => {
      userLogout();
    }).catch((error) => {
    });
  };
  editConversation(i) {
  }
  handelMenu() {
    this.props.setMenuToggle(!this.state.menuExpand);
    this.setState({ menuExpand: !this.state.menuExpand });
  }
  handlerChatListClick(id) {
    this.props.handlerChatListClick(id);
    this.props.setQuestion('', '', '');
    this.props.setNewChat(false);
    //this.setState({ menuExpand:true });    
    if (this.state.isEditChatLabel == 'close' || this.state.selected_chat_id != id) {
      this.props.getChatHistoryDetail(id);
      if (isMobile) {
        this.props.setMenuToggle(!this.state.menuExpand);
        this.setState({ menuExpand: !this.state.menuExpand });
      }
    }
    this.setState({ selected_chat_id: id })
  }
  render() {
    return (
      <div className={`flex flex-col bg-light-2 p-2 history-block pt-3 border-right ${this.state.menuExpand ? "" : "menu-hide"}`}>
        <div className="d-flex gap-5 align-items-center mb-2">
          <button className="enter-text w-100 p-1 flex align-items-center bg-light" onClick={() => this.newChat()}>
            <img src="/images/add_black.png" className="pr-1" alt="send" width={20} /> New Chat
          </button>
          <button className={`navigation bg-white p-2 rounded ${this.state.menuExpand ? "" : "collapsed"}`} onClick={() => this.handelMenu()}>
            <img src="/images/menu.svg" alt="send" width={20} />
          </button>
        </div>
        <div className="chat-history grow-1">
          {this.state.chatList && this.state.chatList.map((chat, index) => {
            return <>
              {/* <small className="my-1">Today</small> */}
              {chat.application_id === 'c98fd773-f374-41fc-bb5c-9779b7fced8a' &&
                <ChatLabel key={index} handlerChatListClick={(id) => this.handlerChatListClick(id)} fetchConvesationHistory={() => this.props.fetchConvesationHistory()} chat={chat} confirmButtonText={'Yes'} closeButtonText="No" selected_chat_id={this.state.selected_chat_id}></ChatLabel>
              }
            </>
          })}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    chatHistoryGet: state.dashboard.chatHistoryGet,
    chatHistoryInfo: state.dashboard.chatHistoryInfo,
    chatHistoryDelete: state.dashboard.chatHistoryDelete,
    selectedChatId: state.dashboard.selectedChatId,
    isEditChatLabel: state.dashboard.isEditChatLabel,
    applicatonList: state.dashboard.applicatonList,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      fetchConvesationHistory,
      getChatHistoryDetail,
      setMenuToggle,
      setNewChat,
      setQuestion,
      selectChatId,
      fetchApplicationList
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatHistory);