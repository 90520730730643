import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    items: PropTypes.array,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
}

const defaultProps = {
    initialPage: 1,
}

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pager: {}, pageInfo: {} };
    }

    componentWillMount() {
        if (this.props.pageInfo && this.props.pageInfo.length) {
            this.setPager(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed       
        if (this.props.pageInfo !== prevProps.pageInfo) {
           
            if (this.props.pageInfo) {
                this.setState({ pageInfo: this.props.pageInfo });
                if (this.props.pageInfo.page) {
                    this.setPager(this.props.pageInfo.page);
                }
            }
        }
    }
    setPerPage(e) {
        let page_info = this.state.pageInfo;
        page_info.perPage = e.target.value;
        page_info.page = 1;
        this.setState({ pageInfo: page_info });

        let pager = this.state.pager;
        pager.perPage = e.target.value;
        this.setState({ pager: pager });

        this.props.onChangePerPage(page_info);
    }

    setPage(page) {
        if (page > 0 && page <= this.state.pager.totalPages) {
            var pager = this.state.pager;

            pager.currentPage = page;
            // update state
            this.setState({ pager: pager });

            // call change page function in parent component
            //this.props.onChangePage(pageOfItems);       
            this.props.onChangePage(pager);
        }
    }
    setPager(page) {

        var { pageInfo } = this.props;

        var pager = this.state.pager;
       /* if (page < 1 || page > pager.totalPages) {
            return;
        } */
        
        // get new pager object for specified page
        pager = this.getPager(pageInfo.total, page, pageInfo.perPage);

        // update state
        this.setState({ pager: pager });

    }

    getPager(totalItems, currentPage, pageSize) {

        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 4) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 4;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 2;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize + 1;
        //var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        var endIndex = totalItems;

        if (pageSize < totalItems) {
            endIndex = pageSize * currentPage
            if (endIndex > totalItems) {
                endIndex = totalItems;
            }
        }

        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        var pager = this.state.pager;

        if (!pager.pages || pager.pages.length < 1) {
            // don't display pager if there is only 1 page
            return null;
        }
        return (
        <div className="col-12">
            <div className="row justify-content-between align-items-center w-100 m-0">
                <div className="col-xl-auto d-flex justify-content-center justify-content-xl-start py-1">
                    <ul className="pagination m-0">
                         <li className={`page-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                            <a href="javascript:void(0)" className="page-link" onClick={() => this.setPage(1)}>First</a>
                        </li> 
                        <li className={`page-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                            <a className="page-link" aria-label="Previous" href="javascript:void(0)" onClick={() => this.setPage(pager.currentPage - 1)}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pager.pages.map((page, index) =>
                            <li key={index} className={`page-item ${pager.currentPage === page ? 'active' : ''}`}>
                                <a className="page-link" href="javascript:void(0)" onClick={() => this.setPage(page)}>{page}</a>
                            </li>
                        )}
                        <li className={`page-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                            <a className="page-link" href="javascript:void(0)" onClick={() => this.setPage(pager.currentPage + 1)} aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                        <li className={`page-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                            <a href="javascript:void(0)" className="page-link" onClick={() => this.setPage(pager.totalPages)}>Last</a>
                        </li> 
                    </ul>
                </div>
                <div className="col-xl-auto text-center py-1">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                        Showing {this.state.pager.startIndex} to {this.state.pager.endIndex} of {this.state.pager.totalItems} entries
                    </div>
                </div>
                <div className="col-xl-auto py-1 d-flex justify-content-center justify-content-xl-end align-items-center py-1">
                    <div className="dataTables_length" id="DataTables_Table_0_length">
                        <label className='d-flex'>Show
                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm mx-1" value={this.state.pageInfo.perPage} onChange={this.setPerPage.bind(this)}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                                <option value="250">250</option>
                                <option value="300">300</option>
                                <option value="350">350</option>
                                <option value="400">400</option>
                                <option value="450">450</option>
                                <option value="500">500</option>
                            </select>
                            entries</label>
                    </div>
                </div>
            </div>
        </div> 
        );
    }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;