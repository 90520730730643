import { SET_CURRENT_USER, SET_PTP_CURRENT_USER, FETCH_CONFIG } from "../actions";

const initialState = {
  validToken: false,
  user: {},
  ptpuser: {},
  config: {},
};

const booleanActionPayload = (payload) => {
  if (payload) {
    return true;
  } else {
    return false;
  }
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        validToken: booleanActionPayload(action.payload),
        user: action.payload,
      };
    case SET_PTP_CURRENT_USER:
      return {
        ...state,
        ptpuser: action.payload
      }
    case FETCH_CONFIG: {
      return {
        ...state,
        config: action.payload
      }
    }
    default:
      return state;
  }
}
