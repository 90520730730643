import axios from "axios";

import { NODEURL, handleError, GET_ERRORS, FETCH_USERS, USERS_DISABLED, USERS_ENABLED, USERS_ADD, FETCH_USER, USERS_EDIT, USERS_DELETE,USERS_RESET } from "../actions/index";
import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
//Admin user
export const AddUser =
  (payload) =>
    async (dispatch) => {
      try {
        let error = {};
        error = {
          errorMessage: ""
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });

        let email = payload.email;
        let password = "Devdigital123#"

        await createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed in 
            let res = userCredential.user;
            //sendEmailVerification(auth.currentUser);
            sendPasswordResetEmail(auth, email);
            //await sendPasswordResetEmail(email);
            try {
              payload.uuid = res.uid;
              const url = `${NODEURL}/api/users`;
              let newUser;
              newUser = await axios.post(url, payload);
              dispatch({
                type: USERS_ADD,
                payload: newUser,
              });
            } catch (error) {
              handleError(error, dispatch);
            }
            // dispatch({
            //   type: USERS_ADD,
            //   payload: res,
            // });
          })
          .catch((err) => {
            let error = {};
            let errorCode = err.code;
            if (errorCode === "auth/email-already-in-use") {
              error = {
                errorMessage: "Email address already exist."
              }
              dispatch({
                type: GET_ERRORS,
                payload: error,
              });
            } else {
              handleError(err, dispatch);
            }
            // ..
          });

      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const EditUser =
  (payload) =>
    async (dispatch) => {
      try {
        const url = `${NODEURL}/api/users/${payload.uuid}`;
        let editUser;
        editUser = await axios.put(url, payload);
        dispatch({
          type: USERS_EDIT,
          payload: editUser,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const createUser = (payload) =>
  async (dispatch) => {
    try {
      const url = `${NODEURL}/api/users/createUser`;
      let createUser;
      createUser = await axios.post(url, payload);
      // dispatch({
      //   type: USERS_EDIT,
      //   payload: editUser,
      // });
    } catch (error) {
      handleError(error, dispatch);
    }
  };
export const fetchUserList =
  (page, perPage, sortColumn = "", sortOrder = "") =>
    async (dispatch) => {
      try {
        const url = `${NODEURL}/api/users`;
        let res;
        res = await axios.get(url);
        dispatch({
          type: FETCH_USERS,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const fetchUserByUid =
  (id, accessToken) =>
    async (dispatch) => {
      try {
        const url = `${NODEURL}/api/users/user/${id}`;
        let res;
        res = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
          },
        });
        // dispatch({
        //   type: FETCH_USER,
        //   payload: res.data,
        // });
        return res.data;
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const disableUser =
  (user) =>
    async (dispatch) => {
      try {
        const url = `${NODEURL}/api/users/disable/${user.uid}`;
        let res;
        res = await axios.put(url);
        dispatch({
          type: USERS_DISABLED,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const enableUser =
  (user) =>
    async (dispatch) => {
      try {
        const url = `${NODEURL}/api/users/enable/${user.uid}`;
        let res;
        res = await axios.put(url);
        dispatch({
          type: USERS_ENABLED,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const deleteUser =
  (userid) =>
    async (dispatch) => {
      try {
        const url = `${NODEURL}/api/users/${userid}`;
        let res;
        res = await axios.delete(url);
        dispatch({
          type: USERS_DELETE,
          payload: res.data,
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const sendResetPassword = (email) =>
  async (dispatch) => {
    try {
      sendPasswordResetEmail(auth, email)
      dispatch({
        type: USERS_RESET,
        payload: email,
      });
      setTimeout(()=>{
        dispatch({
          type: USERS_RESET,
          payload: '',
        });
      },500)
    } catch (error) {
      handleError(error, dispatch);
    }

  }