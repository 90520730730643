import { FETCH_USERS, USERS_DISABLED, USERS_ENABLED, USERS_ADD, USERS_EDIT, USERS_DELETE,USERS_RESET } from "../actions/index";

const INITIAL_STATE = {
  userList: [],
  userStatus: {},
  userAdd: {},
  userDelete: {},
  userResetPassword: ''
};
export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        userList: action.payload,
      }

    case USERS_ADD:
      return {
        ...state,
        userAdd: action.payload,
      }
    case USERS_EDIT:
      return {
        ...state,
        userEdit: action.payload,
      }

    case USERS_DISABLED:
      return {
        ...state,
        userStatus: action.payload,
      }
    case USERS_ENABLED:
      return {
        ...state,
        userStatus: action.payload,
      }
    case USERS_DELETE:
      return {
        ...state,
        userDelete: action.payload,
      }
    case USERS_RESET:
      return {
        ...state,
        userResetPassword: action.payload,
      }
    default:
      return state;
  }
}
