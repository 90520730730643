
import React, { Component } from "react";
import { connect } from "react-redux";

class Chatinterface extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
   
    return (
      <div className="pt-65 bg-dark-theme grow-1 d-flex flex-column">
        <div className="w-100 grow-1 mb-3">
          <div className="bg-light-1 py-3">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="images/profile.png" alt="profile" width={32} />
              <div>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?
              </div>
            </div>
          </div>
          <div className="bg-dark-theme pt-3 pb-1">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="/images/logo-icon.png" alt="ptp" width={32} />
              <div className="grow-1">
                <table class="table table-striped table-hover border mb-0">
                  <thead class="table-primary text-light">
                    <tr>
                      <th scope="col">Deal Name</th>
                      <th scope="col">Close Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">Dell Customs & Border Patrol (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell TSA (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Chattanooga Smart Corridor (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell KLM Air France (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Houston Rail Station / Amtrak (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Cenegenics (AskBoss)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Verisk Analytics</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">FreshBooks</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Melio Payments (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Royal Bank of Canada (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">King Activision Extension & Growth (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">NSure (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                  </tbody>
                </table>
                {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?
                <br></br>
                <br></br>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr:
                <br></br>
                <br></br>
                <ul className="list-type-none">
                  <li className="mb-2"><b>1. Lorem ipsum dolor</b> sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?</li>
                  <li className="mb-2"><b>2. Lorem ipsum dolor</b> sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?</li>
                  <li className="mb-2"><b>3. Lorem ipsum dolor</b> sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua? sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?</li>
                  <li className="mb-2"><b>4. Lorem ipsum dolor</b> sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?</li>
                </ul>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero. */}
              </div>
              <div className="d-flex gap-12">
                <a href="" className="like-dislike"><img src="images/thumb_down_black.png" alt="ptp" /></a>
                <a href="" className="like-dislike"><img src="images/thumb_up_black.png" alt="ptp" /></a>
              </div>
            </div>
          </div>
          <div className="bg-light-1 py-3">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="images/profile.png" alt="profile" width={32} />
              <div>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?
              </div>
            </div>
          </div>
          <div className="bg-dark-theme pt-3 pb-1">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="/images/logo-icon.png" alt="ptp" width={32} />
              <div className="grow-1">
                <table class="table table-striped table-hover border mb-0">
                  <thead class="table-primary text-light">
                    <tr>
                      <th scope="col">Deal Name</th>
                      <th scope="col">Close Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">Dell Customs & Border Patrol (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell TSA (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Chattanooga Smart Corridor (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell KLM Air France (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Houston Rail Station / Amtrak (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Cenegenics (AskBoss)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Verisk Analytics</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">FreshBooks</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Melio Payments (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Royal Bank of Canada (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">King Activision Extension & Growth (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">NSure (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex gap-12">
                <a href="" className="like-dislike"><img src="images/thumb_down_black.png" alt="ptp" /></a>
                <a href="" className="like-dislike"><img src="images/thumb_up_black.png" alt="ptp" /></a>
              </div>
            </div>
          </div>
          <div className="bg-light-1 py-3">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="images/profile.png" alt="profile" width={32} />
              <div>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?
              </div>
            </div>
          </div>
          <div className="bg-dark-theme pt-3 pb-1">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="/images/logo-icon.png" alt="ptp" width={32} />
              <div className="grow-1">
                <table class="table table-striped table-hover border mb-0">
                  <thead class="table-primary text-light">
                    <tr>
                      <th scope="col">Deal Name</th>
                      <th scope="col">Close Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">Dell Customs & Border Patrol (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell TSA (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Chattanooga Smart Corridor (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell KLM Air France (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Houston Rail Station / Amtrak (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Cenegenics (AskBoss)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Verisk Analytics</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">FreshBooks</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Melio Payments (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Royal Bank of Canada (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">King Activision Extension & Growth (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">NSure (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex gap-12">
                <a href="" className="like-dislike"><img src="images/thumb_down_black.png" alt="ptp" /></a>
                <a href="" className="like-dislike"><img src="images/thumb_up_black.png" alt="ptp" /></a>
              </div>
            </div>
          </div>
          <div className="bg-light-1 py-3">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="images/profile.png" alt="profile" width={32} />
              <div>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?
              </div>
            </div>
          </div>
          <div className="bg-dark-theme pt-3 pb-1">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="/images/logo-icon.png" alt="ptp" width={32} />
              <div className="grow-1">
                <table class="table table-striped table-hover border mb-0">
                  <thead class="table-primary text-light">
                    <tr>
                      <th scope="col">Deal Name</th>
                      <th scope="col">Close Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">Dell Customs & Border Patrol (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell TSA (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Chattanooga Smart Corridor (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell KLM Air France (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Houston Rail Station / Amtrak (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Cenegenics (AskBoss)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Verisk Analytics</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">FreshBooks</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Melio Payments (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Royal Bank of Canada (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">King Activision Extension & Growth (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">NSure (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                  </tbody>
                </table>
                {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?
                <br></br>
                <br></br>
                <div className="d-flex justify-content-center">
                  <img src="images/graph.png" alt="ptp" />
                </div> */}
              </div>
              <div className="d-flex gap-12">
                <a href="" className="like-dislike"><img src="images/thumb_down_black.png" alt="ptp" /></a>
                <a href="" className="like-dislike"><img src="images/thumb_up_black.png" alt="ptp" /></a>
              </div>
            </div>
          </div>
          <div className="bg-light-1 py-3">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="images/profile.png" alt="profile" width={32} />
              <div>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?
              </div>
            </div>
          </div>
          <div className="bg-dark-theme pt-3 pb-1">
            <div className="max-w-768 mx-auto w-100 d-flex align-items-start gap-18 chat-interface">
              <img src="/images/logo-icon.png" alt="ptp" width={32} />
              <div className="grow-1">
                <table class="table table-striped table-hover border mb-0">
                  <thead class="table-primary text-light">
                    <tr>
                      <th scope="col">Deal Name</th>
                      <th scope="col">Close Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">Dell Customs & Border Patrol (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell TSA (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Chattanooga Smart Corridor (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell KLM Air France (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Dell Houston Rail Station / Amtrak (BOSS AI)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Cenegenics (AskBoss)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Verisk Analytics</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">FreshBooks</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Melio Payments (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">Royal Bank of Canada (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">King Activision Extension & Growth (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                    <tr>
                      <td scope="row">NSure (SW)</td>
                      <td>09/01/2023</td>
                    </tr>
                  </tbody>
                </table>
                {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua?
                <br></br>
                <br></br>
                <div className="d-flex justify-content-center">
                  <img src="images/pie.png" alt="ptp" />
                </div> */}
              </div>
              <div className="d-flex gap-12">
                <a href="" className="like-dislike"><img src="images/thumb_down_black.png" alt="ptp" /></a>
                <a href="" className="like-dislike"><img src="images/thumb_up_black.png" alt="ptp" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-768 mx-auto w-full enter-text flex bg-light-1 my-3">
          <input type="" className="grow-1" placeholder="Enter Your Text" />
          <button className="enter-text-button">
            <img src="images/send_light.png" className="" alt="send" />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {}
export default connect(mapStateToProps, mapDispatchToProps)(Chatinterface);