
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SET_CURRENT_USER, SET_PTP_CURRENT_USER } from "../actions";
import Dashboard from "./mainTabs/dashboard/Dashboard.js";
import DASHBOARDRESULT from "./mainTabs/dashboard/DashboardResult";
import UserList from "./mainTabs/users/UserList.js";
import Chatinterface from "./mainTabs/dashboard/chat-interface";
import { toggleModal } from "../actions/index";
import * as routes from "../constants/routes";
import setJWTToken from "../securityUtils/setJWTToken";
import { Alert } from "./GlobalComponent/Alert";
import Login from "./mainTabs/authorize/Login";
import FortgotPassword from "./mainTabs/authorize/FortgotPassword";
import Error404 from "./mainTabs/error/error404";
import HeaderInactive from "./mainTabs/header/header-nav-invalid";
import HeaderActive from "./mainTabs/header/header-nav-valid";
import LeftNav from "./mainTabs/header/left-nav";
import Footer from "./mainTabs/footer/footer";

function App() {
  let user = useSelector((state) => state.security.user);

  //sessionStorage.setItem ("jwtToken","Bearer eyJhbGciOiJIUzUxMiJ9.eyJyb2xlIjoiUFJPVklERVIiLCJmdWxsTmFtZSI6Ik1haGV0YSwgSmF5IiwiaWQiOiIzZWJiMmQwYi1mYTdkLTRmMDUtOGY3Zi1iZTE4ZTU2ZmQ4NWQiLCJleHAiOjE2OTI0MjcxODksImlhdCI6MTY5MTU2MzE4OSwidXNlcm5hbWUiOiJqYXkubWFoZXRhQGRldmRpZ2l0YWwuY29tIn0.qp3C7x3WJtaxXLxLKrGPuI9VjBXau7RQdBRyqb7lK8wNCXV2AovWERrFkbrh_HKYwgieq-S1n_FqX4d7bxBvEQ");
  //let validLogin = token.validToken;
  let validLogin = sessionStorage.getItem("jwtTokenPTP");
  //validLogin = "true";

  let Header = null;
  Header = validLogin ? HeaderActive : HeaderInactive;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();
  const leftMenuOpen = useSelector((state) => state.dashboard.leftMenuOpen);
  const jwtToken = sessionStorage.jwtToken;
  const jwtTokenPTP = sessionStorage.jwtTokenPTP;
  if (jwtToken) {
    setJWTToken(jwtToken);
    let decoded = jwt_decode(jwtToken);
    if (Object.values(user).length === 0) {
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
      dispatch({
        type: SET_PTP_CURRENT_USER,
        payload: jwtTokenPTP,
      });
    }

  }
  return (
    <BrowserRouter>
      {validLogin &&
        <LeftNav></LeftNav>
      }
      <div className={`flex flex-col grow-1 w-100  ${leftMenuOpen ? "" : "menu-open"} ${validLogin ? "usertable" : ''}`}>
        <Header
          authUser={null}
          version={""}
          value={value}
          setValue={setValue}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        {validLogin ? <Alert></Alert> : null}
        <Switch>
          <Route exact strict path={routes.LANDING} component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact key={routes.FORGOTPASSWORD} path={routes.FORGOTPASSWORD} component={FortgotPassword} />
          <Route exact key={routes.DASHBOARD} path={routes.DASHBOARD}
            render={(props) => (
              validLogin ?
                <Dashboard {...props} setValue={setValue} setSelectedIndex={setSelectedIndex} />
                : <Login />
            )}
          />
          <Route exact key={routes.DASHBOARDRESULT} path={routes.DASHBOARDRESULT}
            render={(props) => (
              validLogin ?
                <DASHBOARDRESULT {...props} setValue={setValue} setSelectedIndex={setSelectedIndex} />
                : <Login />
            )}
          />

          <Route exact path={'/chatinterface'}
            render={(props) => (
              <Chatinterface {...props} setValue={setValue} setSelectedIndex={setSelectedIndex} />
            )}
          />
          <Route
            exact
            key={routes.USERS}
            path={routes.USERS}
            render={(props) => (
              validLogin ?
                <>
                  <UserList
                    {...props}
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                  />
                </>
                : <Login />
            )}
          />
          <Route component={Error404} />
        </Switch>
        {validLogin &&
          <Footer></Footer>
        }
      </div>
    </BrowserRouter>
  );
}

function mapStateToProps(state) {
  return {
    leftMenuOpen: state.dashboard.leftMenuOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
