import { FETCH_APPLICATION, SET_APPLICATIONID, FETCH_ASKRESULTS, SET_QUESTION, SET_CONVERSATION, SET_CHATHISTORY, GET_CHATHISTORYINFO, DELETE_CHATHISTORY, SELECT_CHAT_ID, SET_LEFT_MENU,SET_NEW_CHAT,EDIT_CHAT_LABEL } from "../actions/index";

const INITIAL_STATE = {
  applicatonList: [],
  applicationId: "",
  chatHistoryGet: "",
  chatHistoryInfo: "",
  chatHistoryDelete: {},
  selectedChatId: "",
  leftMenuOpen: true,
};
export default function dashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_APPLICATION:
      return {
        ...state,
        applicatonList: action.payload,
      }
    case SET_APPLICATIONID:
      return {
        ...state,
        applicationId: action.payload,
      }
    case FETCH_ASKRESULTS:
      return {
        ...state,
        askResults: action.payload,
      }
    case SET_QUESTION:
      return {
        ...state,
        questionObj: action.payload,
      }
    case SET_CONVERSATION:
      return {
        ...state,
        converationAdd: action.payload.data,
      }
    case SET_CHATHISTORY:
      return {
        ...state,
        chatHistoryGet: action.payload.data,
      }
    case GET_CHATHISTORYINFO:
      return {
        ...state,
        chatHistoryInfo: action.payload.data,
      }
    case DELETE_CHATHISTORY:
      return {
        ...state,
        chatHistoryDelete: action.payload.data,
      }
    case SELECT_CHAT_ID:
      return {
        ...state,
        selectedChatId: action.payload,
      }
    case SET_LEFT_MENU: {      
      return {
        ...state,
        leftMenuOpen: action.payload,
      }
    }
    case SET_NEW_CHAT: {      
      return {
        ...state,
        newChatSet: action.payload,
      }
    }
    case EDIT_CHAT_LABEL:
      return {
        ...state,
        isEditChatLabel: action.payload,
      }
    default:
      return state;
  }
}
