import React, { Component } from "react";
import { signInWithEmailAndPassword, signInWithPopup, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { auth, googleProvider } from '../../../firebase';
import jwt_decode from "jwt-decode";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCurrentUser, ptpAuthorization, getEnvConfig } from "../../../actions/securityActions";
import * as routes from "../../../constants/routes";
import Progress from "react-progress-2";
import { fetchUserByUid, FETCH_USER, createUser } from "../../../actions/index";

export const PTP_CLIENTID = process.env.REACT_APP_PTP_CLIENTID;
export const PTP_SECRET = process.env.REACT_APP_PTP_SECRET;



class FortgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      errors: {},
      validLoginInfo: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {


  }
  componentDidUpdate(prevProps) {

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout((e) => {
      if (this.state.username == "") {
        this.setState({ validLoginInfo: false });
      } else {
        this.setState({ validLoginInfo: true });
      }
      // console.log("validLoginInfo:::", this.state.validLoginInfo)
    }, 200);
  }

  onSubmit(e) {
    // this.props.setCurrentUser(token.credential);//
    e.preventDefault();

  }

  sendResetpasswordHandler = async (e) => {

    var validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

    if (!this.state.username.match(validEmailRegex)) {
      let errors = { errorMessage: "Invalid email. Please enter your valid email address",successMessage:"" };
      this.setState({ errors: errors });

      return false;
    }
    try {
      let email = this.state.username;
      await sendPasswordResetEmail(auth, email).then((res) => {
        this.setState({
          errors: '',
          successMessage: `Reset password link sent to ${this.state.username}`,
          errorMessage:"",
          username: "",
        });
      }).catch((error) => {
        let errors = { errorMessage: "Sorry, we do not recognize this email address.",successMessage:"" };
        this.setState({ errors: errors });
      });
    } catch (err) {
      console.error(err);
    }
  };
  render() {
    const { errors } = this.state;
    return (
      <div className="login-bg position-relative">
        <style type="text/css">
          .usertable={'max-width: 100%;'}
        </style>
        <section className="user-section item-center" style={{'min-height':'100vh'}}>
          <div className="p-3 p-md-5 m-2 radius-12 shadow text-center bg-white">
            <figure className="brand-ui">
              <img src="images/Logo2.png" alt="PTP" style={{ maxHeight: 191 }} />
            </figure>
            <h2 className="font-bold font-20 mb-0">Forgot your password?</h2>
            <div>
              {this.state.successMessage !== "" && (
                <div className="error-msg-holder text-success pt-1">
                  {this.state.successMessage}
                </div>
              )}
              {errors.errorMessage && (
                <div className="error-msg-holder pt-1">
                  <div className="error text-danger mt-2">{errors.errorMessage}</div>
                </div>
              )}
              <div className="my-3">
                Enter your email address below and we'll send you a link <br />
                to your email to reset your password.
              </div>

              <div className="form-card my-3">
                <input type="text" autoComplete="off" className={classnames("form-control font-18", { "is-invalid": errors.username, })} placeholder="Email Address" name="username" value={this.state.username} onChange={this.onChange} />
                {errors.username && (
                  <div className="invalid-feedback">{errors.username}</div>
                )}
              </div>
              <button type="submit" disabled={!this.state.validLoginInfo} className={`btn bg-primary-theme text-white my-3 w-100 ${this.state.validLoginInfo ? "active" : ""}`} onClick={this.sendResetpasswordHandler}>Request Link</button>
              <div
                onClick={() => this.props.history.push(routes.LOGIN)}
                className="text-secondary-theme py-3 font-figtree font-500 font-18 pointer"
              >
                Back to login
              </div>
            </div>

          </div>
        </section>
      </div>
    );
  }
}

FortgotPassword.propTypes = {
  login: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  ptpuser: state.security.ptpuser,
  errors: state.errors,
  currentUser: state.security.user,
  config: state.security.config
});

export default connect(mapStateToProps, { setCurrentUser, ptpAuthorization, getEnvConfig, fetchUserByUid, createUser })(FortgotPassword);
