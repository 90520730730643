import React from 'react';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import CONSTANT from '../../components/constants';
import NumberFormat from 'react-number-format';

class Datatable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: {},
            data: [],
            sortInfo: {
            },
            tabelSortable: false
        }
    }

    componentWillMount() {

        this.setState({
            columns: this.props.columns,
            data: this.props.data,
            sortInfo: this.props.sortInfo
        })
    }

    componentDidUpdate(prevProps) {
        //console.log(this.props.data);
        if (prevProps.columns !== this.props.columns) {
            this.setState({
                columns: this.props.columns
            });
        }
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data
            });
            if (this.props.pageInfo) {
                if (this.props.pageInfo.perPage >= this.props.data.length && this.props.tabelSortable) {
                    this.setState({ tabelSortable: true })
                } else {
                    this.setState({ tabelSortable: false })
                }
            }
        }
    }

    sortColumn(sortBy, sortOrder) {
        if (sortOrder === 'asc') {
            sortOrder = 'desc';
        } else {
            sortOrder = 'asc';
        }
        let sortInfo = {
            sortBy: sortBy,
            sortOrder: sortOrder
        }
        this.setState({
            sortInfo: sortInfo
        });
        this.props.onSortColumn(sortInfo);
    }

    getProperty(object, propertyName) {
        var parts = propertyName.split("."),
            length = parts.length,
            i,
            property = object || this;

        for (i = 0; i < length; i++) {
            property = property[parts[i]];
        }

        return property;
    }

    renderColumn(col, object) {
        var columnType = (col['type']) ? col['type'] : ((col['selector'] === 'action' || col['selector'] === 'checkrow' || col['selector'] === 'resetpassword') ? 'html' : col['selector']);

        switch (columnType) {
            case 'html':
                return col.cell(object);
            case 'phone-number':
                // return <NumberFormat value={this.getProperty(object, col.selector)} displayType={'text'} format="(###) ###-####" />
            case 'date':
                return moment.utc(this.getProperty(object, col.selector)).format(CONSTANT.displayDateFormat);
            case 'datetime':
                return moment.tz(this.getProperty(object, col.selector), CONSTANT.centralTimezone).format('M/D/YYYY h:mm a');
            case 'datetimes':
                var dt = moment.tz(this.getProperty(object, col.selector), CONSTANT.centralTimezone);
                return dt.format(CONSTANT.dateFormat) + "<span className='d-block'>" + dt.format(CONSTANT.timeFormat) + "</span>";
            case 'time':
                return moment.tz(this.getProperty(object, col.selector), CONSTANT.centralTimezone).format(CONSTANT.timeFormat);
            default:
                return this.getProperty(object, col.selector);
        }
    }

    newRecOrder(data) {
        //console.log(data);
        this.setState({ data: data });
    }

    sortEnd() {
        //console.log(this.state.data);
        this.props.setPriorityOrder(this.state.data)
    }

    onUpdate(evt) {
        [].forEach.call(evt.from.getElementsByClassName('omaido__sortable_item'), function (el, index) {
       
            el.setAttribute("data-id", index);
        });
    }

    render() {
        return (
            <Table responsive striped bordered hover className="table table-striped table-hover border">
                <thead class="table-primary text-light">
                    <tr>
                        {this.state.columns && this.state.columns.map((val, index) => {
                            return (
                                <th className={`${(val.headerClassName) ? val.headerClassName : ''}`} >
                                    {val.sortable ?
                                        <a href="javascript:void(0)" class="text-white btn btn-xs btn-link p-0 text-decoration-none shadow-none" onClick={() => this.sortColumn(val.selector, this.state.sortInfo.sortOrder)}>
                                            {val.name}
                                            {this.state.sortInfo.sortBy === val.selector ? this.state.sortInfo.sortOrder === 'asc' ? <span><i class="fas fa-arrow-up"></i></span> : <span><i class="fas fa-arrow-down"></i></span> :
                                                <span><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-down"></i></span>}

                                        </a>
                                        : val.selector === 'checkrow' ? val.header() : val.name}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                {!this.state.tabelSortable ?
                    <tbody>

                        {this.state.data && this.state.data !== null && this.state.data.length > 0 ? this.state.data.map((val, index) => {

                            return (
                                <tr>
                                    {this.state.columns && this.state.columns.map((col, index) => {
                                        return (
                                            <td className={`${(col.columnClassName) ? col.columnClassName : ''}`} >
                                                {
                                                    this.renderColumn(col, val)
                                                }
                                            </td>
                                        )
                                    })}

                                </tr>
                            )
                        }) : <tr><td colSpan={this.props.colSpan} className="text-center">No Record Found</td></tr>}
                    </tbody>
                    :
                    <>                       
                    </>
                }
            </Table >
        );
    }
}

export default Datatable;

